import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { margin: "10px" } },
    [
      _vm.provider == "gitlab"
        ? _c("div", [_c("login-by-gitlab"), _c("br"), _vm._v(" OR\n    ")], 1)
        : _vm._e(),
      _c(VTextField, {
        attrs: {
          "append-icon": _vm.showToken ? "mdi-eye" : "mdi-eye-off",
          type: _vm.showToken ? "text" : "password",
          readonly: _vm.isGitLogin,
          error: _vm.githubTokenError,
          rules: [
            _vm.gitInfoRules.required,
            !_vm.githubTokenError || "Make sure your github token is correct",
          ],
          label: "token",
        },
        on: {
          "click:append": function ($event) {
            _vm.showToken = !_vm.showToken
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _vm._v("\n            Access Token\n            "),
                _c(
                  VIcon,
                  {
                    staticStyle: {
                      "vertical-align": "middle",
                      "margin-top": "-3px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openGuide()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                mdi-help-circle-outline\n            "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.gitToken,
          callback: function ($$v) {
            _vm.gitToken = $$v
          },
          expression: "gitToken",
        },
      }),
      _c(
        VBtn,
        {
          on: {
            click: function ($event) {
              return _vm.saveToken()
            },
          },
        },
        [_vm._v("SAVE")]
      ),
      _c(
        VBtn,
        {
          attrs: { color: "red", text: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("\n        CANCEL\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }